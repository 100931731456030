import request from "@/utils/request";
import { setAppName } from "@/utils/common";
const ipBaseURL = "https://huanxinxiang.ai-me.cn";
const appName = setAppName();

// 欢心享发起签约
export const alipayPay = (data, params, headers) =>
  request({
    url: "/huanxinxiang-service/v1/alipay-pay/",
    method: "post",
    ipBaseURL,
    data,
    params,
    headers,
  });


  // 上报签约
export const alipayCallbackPay = (data, params) => request({
  url: '/huanxinxiang-service/v1/alipay-callback-pay/',
  method: 'post',
  data,
  params,
  headers: {
    "App-Name": appName,
  },
});